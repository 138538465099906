/**
 * Function to return a mix color with white
 */
@function tint($color, $percent) {
    @return mix(#fff, $color, $percent);
}

/**
 * Function to return a mix color with black
 */
@function shade($color, $percent) {
    @return mix(#000, $color, $percent);
}
