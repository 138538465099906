@media print {
    // Page - product detail
    .catalog-product-view {
        .column.main {
            .related-products-container {
                display: none;
            }

            .product-info-main-wrapper {
                flex-basis: 10.2rem;
                margin-left: auto;

                .product-info {
                    &-price {
                        &-container {
                            box-shadow: none;
                            border: 0.1rem solid $color-blue-light;
                            border-radius: 0.3rem;
                        }

                        &-banner {
                            padding: $indent__xs $indent__s $indent__xs $space-1;
                            font-size: $font-size-xxs;
                            line-height: 1.3rem;
                            letter-spacing: -0.005rem; // like it on Sketch
                            color: $color-main-dark;
                            border-bottom: 0.1rem solid $color-disabled-light;
                            text-transform: none;
                            font-weight: $font-weight__regular;
                        }

                        &-wrapper {
                            padding: $indent__s;

                            .product-info-price {
                                flex-direction: column;

                                .price-container {
                                    margin-right: 0;
                                    font-weight: $font-weight__bold;
                                    font-size: $font-size-m;
                                    line-height: 2.1rem;
                                    color: $color-grey-dark;
                                }

                                .unit {
                                    margin-top: 0.6rem;
                                    color: $text-price-unit-color;
                                    font-size: $text-price-unit-size;
                                    line-height: 1.3rem;
                                    display: block;

                                    .conditioning {
                                        opacity: 1;
                                        color: inherit;
                                        font-size: inherit;
                                        line-height: 1.3rem;
                                        margin: 0;
                                    }
                                }

                                &__public-price {
                                    font-weight: $font-weight__light;
                                    color: $color-grey-dove !important;
                                    font-size: $font-size-xxs;
                                    line-height: 1.3rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        .#{$css-namespace}productBrand {
            margin-bottom: $indent__xs;
            line-height: 1.7rem;

            &Title {
                text-decoration: none !important;
                color: $color-grey-dove !important;
                font-weight: $font-weight__heavier;
                font-size: $font-size-s;
                line-height: 1.7rem;
                text-transform: uppercase;
            }
        }

        .product-info-custom {
            display: flex;
            flex-direction: row;
            flex: none !important;
            height: auto;
            margin-top: 1.5rem;

            &-container {
                box-shadow: none;
                margin-top: 1.5rem;
                padding-top: 0;
                padding-bottom: 0;
            }

            &-header {
                .#{$css-namespace}actions {
                    display: none;
                }

                .page-title {
                    color: $color-grey-dark;
                    font-weight: $font-weight__bold;
                    font-size: $font-size-m;
                    line-height: 2.1rem;
                }

                .gtm-product-info,
                .product-head-wrapper {
                    > .product {
                        + .product {
                            margin-top: 0.5rem;
                        }
                    }

                    .attribute {
                        font-weight: $font-weight__regular;
                        font-size: $font-size-xxs;
                        line-height: 1.3rem;
                        color: $color-grey-dark;
                    }
                }

                .references {
                    .separator {
                        font-weight: $font-weight__light;
                        font-size: $font-size-xxs;
                        line-height: 1.3rem;
                        color: $color-grey-dove !important;
                    }
                }
            }

            .product-brand-logo {
                display: none;
            }

            .references {
                flex-direction: column;

                .ref {
                    margin-left: 0;
                }
            }

            .product-info-price-container-container {
                box-shadow: none;
            }

            .gallery-placeholder {
                width: 19rem;
                max-width: 19rem;
                height: 19rem;
                margin: 0 2.1rem 0 0;
                padding: 0;
                flex: 0 0 19rem !important;
                border: 0.1rem solid $color-blue-light;
                border-radius: 0.3rem;

                .fotorama {
                    &-item {
                        height: 100%;
                    }

                    &__wrap {
                        height: 100%;
                    }
                }

                .fotorama__nav-wrap,
                .fotorama__nav-wrap--horizontal {
                    display: none;
                }

                .fotorama__stage {
                    position: relative;
                    width: 100% !important;
                    height: 100% !important;
                    max-width: 16rem !important;
                    max-height: 15rem !important;
                    padding-bottom: 0 !important;

                    .fotorama__stage__shaft {
                        max-width: 19rem;
                        height: 100%;

                        .fotorama__img {
                            position: static;
                            transform: none;
                        }
                    }

                    &__frame {
                        align-items: flex-start;
                        min-height: 19rem !important;
                    }
                }

                .fotorama__stage:after {
                    display: none;
                }
            }

            .gallery-placeholder,
            .product-head-wrapper {
                flex: 1;
            }

            .#{$css-namespace}attribut_data {
                display: none;
            }
        }

        .#{$css-namespace}discriminant {
            margin-right: $indent__base;

            &__header {
                display: none;
            }

            &__form {
                display: inline-flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-left: -$indent__s;
                margin-bottom: -$indent__s;
            }

            &__parameter {
                &_container {
                    display: inline-flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: $indent__s;
                    margin-left: $indent__s;
                }

                &_label {
                    font-weight: $font-weight__bold;
                    color: $color-grey-dark;

                    &_container {
                        display: flex;
                        margin: 0;
                    }

                    &_unit {
                        &::after,
                        &::before {
                            display: inline-block !important;
                            content: ' ';
                            white-space: pre;
                        }
                    }
                }

                &_list {
                    display: inline;
                    margin: 0;
                }

                &_item {
                    margin: 0;
                }

                &_button {
                    padding: 0;
                    border: none;
                    box-shadow: none !important;
                    height: auto;

                    &:not(.selected) {
                        display: none;
                    }

                    &::before {
                        display: inline-block !important;
                        content: ': ';
                        white-space: pre;
                    }
                }

                &_list,
                &_item,
                &_label,
                &_label_unit,
                &_button {
                    font-size: $font-size-xxs;
                    line-height: 1.3rem;
                }
            }
        }

        .product-info-custom {
            .product-info-main__add-cart-container,
            .product-store-more,
            .product.share,
            .product.print {
                display: none;
            }
        }

        .product-left-container {
            width: 100%;
            margin-top: 0;

            > div + div {
                margin-top: 2rem;
            }

            .#{$css-namespace}description {
                font-size: $font-size-xs;
                line-height: 1.5;
                min-height: 0;
                margin-top: 0;
                padding-top: 0;
                padding-bottom: 0;
            }

            .#{$css-namespace}title-section {
                font-weight: $font-weight__heavier;
                font-size: $font-size-s;
                line-height: 1.7rem;
                text-transform: uppercase;
                color: $color-grey-dove !important;
                margin-bottom: $indent__s;

                span {
                    display: inline;
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    color: inherit !important;
                }
            }

            .product-infos-container {
                .#{$css-namespace}attribut_data {
                    width: 100%;
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;

                    .attributes--overflow {
                        overflow: visible;
                    }

                    form {
                        flex: 1;
                    }
                }

                .#{$css-namespace}limit_action,
                .#{$css-namespace}documentation {
                    display: none;
                }
            }

            .#{$css-namespace}product_page_title {
                border-bottom: none;
                font-size: $font-size-xl;
                padding-bottom: 0;
                margin: 2rem 0;
            }

            .attributes {
                height: auto !important;

                > li {
                    .attribute {
                        dt,
                        dd {
                            padding: 0;
                            font-size: $font-size-xs;
                            line-height: 1.5;
                        }

                        dt {
                            font-weight: $font-weight__heavier;
                        }

                        dd {
                            font-weight: $font-weight__light;
                            color: $color-grey-dark2 !important;
                        }
                    }

                    + li {
                        margin-top: $indent__s;
                    }
                }
            }

            .product.anchor,
            .dc_more-details {
                display: none;
            }
        }
    }
}
