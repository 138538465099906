@mixin lib-css-box-sizing($property) {
    -moz-box-sizing: $property;
    -webkit-box-sizing: $property;
    box-sizing: $property;
}

/*
 * @mixin 'lib-css-box-sizing-border-box-reset'
 * @desc apply box-sizing: border-box for current element and all nested elements
 * @example
 *      .box {
 *        @include lib-css-box-sizing-border-box-reset();
 *      }
 */

@mixin lib-css-box-sizing-border-box-reset() {
    box-sizing: border-box;

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
}
