// TODO: manage agnostic common color palette (by default we use PROLIANS color-palette here)
// TODO: a11y contrast issues (need UX/UI rework for colors - WCAG AA ratio)
// Common color palette

$color-main-dark: #191c1f;
$color-main-light: #fff;

$color-error: #FF000;
$color-warning: #f88e00; // Same as $color-orange2
$color-error-link: #d12e31;
$color-warning-secondary: #c67200;
$color-success: #008800;
$color-success-secondary: #37872d;

$color-error-background: #ffeaed;
$color-waring-background: #fff1c3;
$color-success-background: #e8f5e9;

$color-drag-hover: #f1f1f1;

$color-white: #fff;
$color-black: #000;
$color-red: #ff0404; // Same as $color-red14
$color-red-light: #ffd9d9;
$color-red-promo: #ff0000;
$color-blue-promo: #0789f4; // Same as $color-secondary
$color-grey: #ccc; // Same as $color-gray80
$color-grey-dark: #2a2a2a;
$color-grey-dark2: #3c3d3e;
$color-grey-dove: #6d6d6d; // Same as $color-gray43
$color-grey-argent: #d2d2d2; // Same as $color-gray83
$color-grey-light: #dddddd; // Same as $color-gray-light8
$color-grey-light2: #ccc; // Same as $color-gray80
$color-grey-light3: #aeb4c2;
$color-blue-background: #003763;
$color-blue-dark: #005296;
$color-blue-light: #e5edf4;
$color-blue-background-1: #f4f6fb;
$color-dark-grey: #515151;
$color-blue-very-dark: #013763;

$color-primary: #005296;
$color-primary-darken: shade($color-primary, 20%);
$color-secondary: #0789f4; // warning: no a11y compliance
$color-secondary-darken: shade($color-secondary, 20%);
$color-sub-secondary: #03488f;
$color-accent: #f88e00; // Same as $color-warning
$color-accent-darken: shade($color-accent, 20%);
$color-pickup: #ff6d00;
$color-orange: #f66d48;
$color-green: #0bc763;
$color-green-promo: #53AE57;
$color-mauve: #a9b3ea;
$color-purple: #9021ff;
$color-yellow-dark: #ffcb69;

$color-grey-background: #f4f6fb;
$color-grey-background-light: #f8f8f8;
$color-grey-border-light: #d6d6d6;
$color-grey-border-light2: #dddddd; // Same as $color-grey-light

$color-tertiary-1: #e1e3e5; // map with dark foreground
$color-tertiary-2: #b8487b; // map with light foreground
$color-tertiary-3: #fdc23a; // map with dark foreground
$color-tertiary-4: #bf2a44; // map with light foreground
$color-tertiary-5: #0db36e; // map with dark foreground
$color-tertiary-6: #f5983b; // map with dark foreground
$color-tertiary-7: #b3d350; // map with dark foreground
$color-tertiary-8: #0aa4c8; // map with dark foreground
$color-tertiary-9: #c04d28; // map with light foreground
$color-tertiary-10: #a2abaa; // map with dark foreground

$color-disabled-dark: #666; // Same as $color-gray40 &&  Remove all redefinitions of the same hexa in Descours
$color-disabled-normal: #bfbfbf; // TODO clear variable for disabled state
$color-disabled-light: $color-grey-argent;

$color-background-default: $color-main-light;
$color-text-default: $color-main-dark;
$color-text-light: #7f7f7f; // TODO design toolkit (+a11y contrast issue)
$color-text-lighter: #a1a4a8;
$color-text-invert: $color-main-light;

$gamme-list: (); // specific store
$color-background-page: $color-white;

$color-scrollbar-track: #efefef; // Todo : remove as it seems not used
$color-scrollbar-bar: #b9b9b9;
$color-disabled: #bfbfbf;
$color-disabled-checkout: #e7e7e7;
$disabledTextColor: #3e3e3e; // same as $color-disabled-text below
$color-white-smoke: #f5f5f5;
$color-white-smoke-2: $color-blue-light; // Todo : need to be a variable in figma for easier toolkit mapping

$color-collaborator-admin: #f8c100;
$color-collaborator-admin-demo: #8bcad9;
$color-collaborator-buyer: #206e8c;
$color-collaborator-guest: #bf7e7e;
$color-collaborator-demo: #ff974f;

$color-workflow-sidebar-border: #666768;

$color-loyalty-purple: #4141aa;
$color-loyalty-blue-grey: #f4f5fb;
$color-disabled-text: #3e3e3e;
$color-disabled-background: #bfbfbf;
$color-icon-grey: #666666; // Same as $color-gray40
$color-gray-light9: #e1e3e5; // Same as $color-gray-light9

$color-tables-blue-extra-light: #e0eaf2;
$color-tables-blue-dark: #9cbcd6;
$color-border-grey-light: #dcdee2; // Same as $color-gray97

$color-tooltip-background: #DEF0FF;

$color-info-price-banner-background: $color-blue-very-dark;

$color-menu-mobile-default: $color-primary;
$color-menu-mobile-links: $color-secondary;

$color-gray-light-7: #1d1d1b; // Same as $color-gray-light7

$color-form-blue-standard: #0789f4; // Force same color cross-platform
$color-form-border: $color-primary;
$color-form-arrow: $color-secondary;
$color-form-search-submit-background: $color-secondary;
$color-form-search-submit-background-darken: $color-secondary-darken;

$color-checkout-step-active: $color-secondary;
$color-checkout-delivery-online: $color-primary;
$color-checkout-delivery-pickup: $color-accent;
$color-checkout-summary-delivery: $color-sub-secondary;
$color-checkout-summary-delivery-click-collect: $color-accent;
$color-checkout-payment-label: $color-blue-dark;

$color-pagination-step-current-background: $color-primary;

$color-cms-brand-index-link: $color-sub-secondary;
$color-cms-brand-index-title: $color-sub-secondary;

$color-availability-opened: $color-tertiary-5;
$color-availability-closed: $color-error;
$color-availability-unavailable: $color-error-link;
$color-availability-available: $color-success-secondary;
$color-availability-low: $color-warning;

$color-useraccount-tag-border: $color-secondary;
$color-useraccount-tag-background: $color-secondary;

$color-product-header-delivery-online-background: $color-checkout-delivery-online;
$color-product-header-delivery-pickup-background: $color-checkout-delivery-pickup;

$color-order-status-canceled: $color-error-link; // to fit with $color-availability-unavailable color logic
$color-order-status-pending: $color-secondary;

$color-link-hover: $color-primary;

$color-search-layer-icons: $color-primary;

$color-message-notice: #0063CB;
$color-message-notice-background: #E5EFFA;
$color-message-warning: $color-warning;
$color-message-warning-background: #FFF3E5;
$color-message-error: #D12E31;
$color-message-error-background: #FAEAEA;
$color-message-success: $color-success-secondary;
$color-message-success-background: #EBF3EA;

$loyalty-PROLIANS-green: #1BC411 !default;
$loyalty-PROLIANS-purple: #793AB0 !default;
$loyalty-PROLIANS-disabled: $color-disabled;
