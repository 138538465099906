.print-header-logo {
    position: absolute;
    right: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    padding-top: 0.3rem;
    max-width: 2.27rem;
}

@media print {
    .print-only {
        display: block !important;

        &-inline-flex {
            display: inline-flex !important;
        }
    }

    .screen-only,
    .header__wrapper,
    .page-header,
    .breadcrumbs,
    .#{$css-namespace}link-back,
    .backToProductLink,
    .page-footer,
    .zendeskchat,
    .product-availability-info {
        display: none !important;
    }

    .print-footer {
        display: block;
        font-size: $font-size-xs;
        margin-top: $indent__base;
        padding: 0 $indent__s;
    }

    .page-wrapper {
        padding-top: 0 !important; // Sticky header adds padding in JS

        .page.messages {
            display: none;
        }

        .columns {
            display: block;
        }
    }

    .column.main {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;

        .block div {
            // Avoid cut-in-half element between pages
            break-inside: avoid;
        }
    }

    .print-header-logo {
        margin: 0;
        position: static;
        transform: none;
        max-width: 12.6rem;
        padding: 1.5rem 0 0 1.5rem;
    }

    .#{$css-namespace}form-quantity-picker {
        &_input {
            &-wrapper {
                width: auto;
                border: none;
            }
        }
    }

    .#{$css-namespace}order-address-area {
        // Open specific collapsed panel
        [data-collapsible='true'] {
            > [role='tab'] > button {
                display: none !important;
            }

            > [role='tabpanel'] {
                display: flex !important;
            }
        }
    }

    // Page - checkout cart index
    .checkout-cart-index {
        .column.main {
            padding: 0 2rem !important;
        }

        .page.messages {
            display: none;
        }

        .block.#{$css-namespace}dashboard_info {
            .#{$css-namespace}box_content_title strong {
                color: $color-primary !important;
            }
        }

        .block.block-dashboard-info {
            display: initial;

            .block-title {
                display: none;
            }

            .block-content {
                margin: 2rem;
                display: flex;
                justify-content: center;

                .box {
                    border: 0.1rem $color-gray94 solid;
                    padding: 2rem;
                    border-radius: 0.5rem;

                    .box-title {
                        span {
                            color: $color-sub-secondary !important;
                        }

                        font-size: 1.9rem;
                    }
                }

                .box-newsletter {
                    .box-content {
                        line-height: 1.4;
                    }

                    margin-left: 2rem;
                }

                .box-information {
                    .box-content {
                        line-height: 1.4;
                    }
                }

                .box-actions {
                    display: none;
                }

                .box-phone {
                    display: block;
                }

                .mobile-phone {
                    display: none !important;
                }
            }
        }

        header {
            position: relative;

            a {
                display: none;
            }

            .logo {
                margin: 2rem;
            }
        }

        a {
            text-decoration: none !important;
        }

        .cart-header {
            display: none;
        }

        .cart-container {
            position: relative;
            display: block;

            .cart-date {
                display: block;
                margin: 0 2rem;
                background-color: $color-sub-secondary !important;
                -webkit-print-color-adjust: exact;
                padding: 1rem;
                color: $color-white !important;
                font-size: $font-size-m;
                font-family: $font-print;
            }

            .cart-summary {
                border: inherit;
                box-shadow: inherit;
                margin-bottom: 0;

                &-container {
                    margin: 0;
                }

                .block.discount {
                    display: none;
                }

                .checkout-methods-items {
                    display: none;
                }

                .summary,
                .convertwishlist {
                    display: none;
                }

                .cart-totals {
                    .table-wrapper {
                        .data.table {
                            tr {
                                &.summary__shipping-info {
                                    display: none;
                                }

                                &.grand.totals {
                                    th {
                                        font-size: 1.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .summary.title {
                display: none;
            }

            .cart-summary-wrapper {
                right: 2rem;
                width: 50%;
                margin: 0;
                margin-left: auto;

                .cart-summary-footer {
                    display: none;
                }
            }

            .form-cart {
                float: inherit;
                width: inherit;
                margin: 0 2rem;
                border-width: 0 0.1rem 0.1rem;
                border-color: $color-disabled-light;
                border-style: solid;
            }
        }

        .cart.main.actions {
            display: none;
        }

        .page-footer {
            display: none;
        }

        .cart.table-wrapper {
            .items {
                thead {
                    th {
                        font-weight: $font-weight-bold;
                    }

                    .col.availability {
                        display: none;
                    }
                }

                .cart.item {
                    .item-info {
                        .product-item-details {
                            .product-brand,
                            .product-item-name {
                                margin-bottom: 0;
                            }
                        }

                        .product-item-photo-picto {
                            display: none;
                        }

                        .col.qty {
                            button {
                                display: none;
                            }

                            input {
                                border-color: transparent;
                                font-weight: $font-weight-regular;
                            }
                        }

                        .col.availavility {
                            display: none;
                        }

                        .col.subtotal {
                            font-family: Arial, 'Helvetica Neue', sans-serif;
                        }

                        dt,
                        .price-excluding-tax .price {
                            font-weight: $font-weight-regular;
                        }
                    }

                    .actions-toolbar {
                        display: none;
                    }
                }
            }
        }

        .#{$css-namespace}products-section_products-table {
            .#{$css-namespace}order-items {
                &_wrapper {
                    padding: 0 1rem;
                }

                thead {
                    display: table-header-group !important;

                    th {
                        white-space: nowrap;

                        &.qty {
                            text-align: center;
                        }

                        &.subtotal {
                            text-align: right;
                        }
                    }
                }

                .#{$css-namespace}item-table_row {
                    border-width: 0.1rem 0 0;
                    border-color: $color-disabled-light;

                    &-item {
                        padding-left: 0;

                        &_subtotal {
                            white-space: nowrap;
                        }
                    }

                    &-delete_text {
                        display: none;
                    }

                    &-availability {
                        display: none;
                    }

                    &-qty {
                        text-align: center;
                    }

                    &-price {
                        text-align: right;
                    }

                    &-promo-text {
                        display: none;
                    }

                    &.d-flex {
                        display: table-row;

                        td.#{$css-namespace}item-table_row-promo-badge.fullwidth {
                            display: block;
                            box-sizing: border-box;
                            clear: both;
                        }
                    }
                }
            }

            [data-update-qty]:not([data-update-qty='enter']) {
                display: none;
            }

            .#{$css-namespace}form-quantity-picker_input[type='number'] {
                border: none;
            }
        }

        .#{$css-namespace}progress-bar,
        .product-left-container,
        .cart-wrapper_description,
        .#{$css-namespace}empty-cart-link,
        .#{$css-namespace}item-table_row-delete {
            display: none;
        }

        .cart-wrapper {
            padding: 2rem 0 0;

            .cart-wrapper_header {
                .cart-wrapper_description,
                .quick-order {
                    display: none;
                }
            }

            .cart-wrapper-inner {
                margin-top: 2rem;
            }
        }

        .#{$css-namespace}block_title {
            display: none;
        }

        .#{$css-namespace}dashboard {
            &_info {
                display: flex !important;
            }

            &_container {
                width: 100%;

                &_content {
                    display: flex;
                    flex-direction: row-reverse;
                    column-gap: 1rem;

                    .#{$css-namespace}box_content_title {
                        font-size: $font-size-m;
                    }
                }
            }
        }

        .#{$css-namespace}box_content_custom {
            flex: 1;
            padding: 1rem;
            border: 0.1rem solid rgba($color-black, 0.12);
            border-radius: 0.3rem;
        }

        .#{$css-namespace}box_content {
            &_text {
                &_mobile-phone {
                    display: none;
                }
            }
        }
    }
}
