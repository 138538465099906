$color-main-dark: #191c1f;
$color-main-light: #fff;

$color-error: #e30324;
$color-warning: #f88e00; // TODO Remove redefinition of the same hexa in Descours
$color-success: #008800;

$color-disabled-dark: #666;
$color-disabled-light: #d2d2d2; // Same as $color-gray83

$color-primary: #00a3cf;
$color-primary-darken: shade($color-primary, 20%);
$color-secondary: #20758c;
$color-secondary-darken: shade($color-secondary, 20%);
$color-sub-secondary: $color-primary;

$color-blue-very-dark: $color-primary-darken;

// specific Hydralians
$color-tertiary-11: #57c9e2; // map with dark foreground
$color-tertiary-12: #8dbf3d; // map with dark foreground
$color-tertiary-13: #2b88a7; // map with light foreground
$color-tertiary-14: #5769ae; // map with light foreground
$color-tertiary-15: #2fb49e; // map with dark foreground
$color-tertiary-16: #f3774c; // map with dark foreground
$color-tertiary-17: #78c3ec; // map with dark foreground
$color-tertiary-18: #fbb122; // map with dark foreground
$color-tertiary-19: #8f3f94; // map with light foreground
$color-tertiary-20: #a2abaa; // map with dark foreground

$color-background-default: $color-main-light;
$color-text-default: $color-main-dark;
$color-text-light: #7f7f7f; // TODO design toolkit (+a11y contrast issue)
$color-text-invert: $color-main-light;

// TODO: manage category colors and change naming
$gamme-list: (
    'arrosage': (
        $color-tertiary-12,
        $color-main-dark,
    ),
    'tuyau-arrosage': (
        $color-tertiary-2,
        $color-main-light,
    ),
    'piscine': (
        $color-tertiary-11,
        $color-main-dark,
    ),
    'brumisation': (
        $color-tertiary-17,
        $color-main-dark,
    ),
    'eclairage-paysager': (
        $color-tertiary-18,
        $color-main-dark,
    ),
    'irrigation-agricole': (
        $color-tertiary-7,
        $color-main-dark,
    ),
    'fournitures-diverses': (
        $color-tertiary-20,
        $color-main-dark,
    ),
    'reseau': (
        $color-tertiary-15,
        $color-main-dark,
    ),
    'pompage': (
        $color-tertiary-13,
        $color-main-light,
    ),
    'traitement-eau': (
        $color-tertiary-14,
        $color-main-light,
    ),
    'fontaine': (
        $color-tertiary-16,
        $color-main-dark,
    ),
    'filtration': (
        $color-tertiary-2,
        $color-main-light,
    ),
    'genie-climatique': (
        $color-tertiary-13,
        $color-main-light,
    ),
    'pieces-detachees': (
        $color-tertiary-20,
        $color-main-dark,
    ),
);

$color-scrollbar-track: #efefef; // TODO: use $color-white-dark-smoke instead
$color-scrollbar-bar: #b9b9b9;

$color-sidebar-customer-name: $color-primary;
$color-sidebar-customer-account-number: $color-secondary;

$color-sidebar-account-management: $color-primary;

$color-info-price-banner: $color-secondary;
$color-info-price-border: $color-primary;
$color-info-price-qty-text: $color-secondary;
$color-info-price-promo: $color-red;
$color-info-price-qty-background: transparentize($color-primary, 0.95); // Todo : remove as it seems not used
$color-qty-background: $color-secondary; // Todo : remove as it seems not used

$color-contact-faq-arrow: $color-secondary;
$color-input-autocomplete-search-btn: #007a9c;
$color-input-autocomplete-search-border: #029bc4;

// For resize purposes, the shape has been done in CSS instead of a SVG, which requires to define the background color.
$color-loyalty-shape-background: #def2f8;

// Duplicated from Descours var-colors because heritage of vars is not taking this file's values like $color-primary $color-secondary etc
$color-menu-mobile-default: $color-secondary;
$color-menu-mobile-links: $color-secondary;

$color-form-border: $color-primary;
$color-form-arrow: $color-secondary;
$color-form-search-submit-background: $color-secondary;
$color-form-search-submit-background-darken: $color-secondary-darken;

$color-checkout-step-active: $color-secondary;
$color-checkout-delivery-online: $color-primary;
$color-checkout-delivery-pickup: $color-accent;
$color-checkout-summary-delivery: $color-sub-secondary;
$color-checkout-summary-delivery-click-collect: $color-accent;
$color-checkout-payment-label: $color-blue-dark;

$color-pagination-step-current-background: $color-primary;

$color-cms-brand-index-link: $color-sub-secondary;
$color-cms-brand-index-title: $color-sub-secondary;

$color-availability-closed: $color-error;
$color-availability-low: $color-warning;

$color-useraccount-tag-border: $color-secondary;
$color-useraccount-tag-background: $color-secondary;

$color-product-header-delivery-online-background: $color-checkout-delivery-online;
$color-product-header-delivery-pickup-background: $color-checkout-delivery-pickup;

$color-order-status-pending: $color-secondary;

$color-link-hover: $color-primary;

$color-search-layer-icons: $color-primary;
