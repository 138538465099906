$font-default: 'Roboto', 'Arial', sans-serif;
$font-secondary: 'Campton', 'Roboto', 'Arial', sans-serif;
$font-print: Arial, 'Helvetica Neue', Helvetica, sans-serif;
$font-icons: 'dcfonts-icons';

$font-size-root: 62.5%; // ~10px (default browser = 16px) => reset for use 'rem' unit instead of 'px'
$font-size-default: 1.4rem; // 14px
$font-size-xxs: 0.8rem; // 8px
$font-size-xs: 1rem; // 10px
$font-size-xs-l: 1.1rem; // 11px
$font-size-s: 1.2rem; // 12px
$font-size-m: 1.6rem; // 16px
$font-size-l: 1.8rem; // 18px
$font-size-xl: 2rem; // 20px
$font-size-xxl: 3rem; // 30px
$font-size-xxxl: 5rem; // 50px

$line-height-default: #{(18/14)};

$font-weight-light: 300;
$font-weight-regular: normal; // 400
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: bold; // 700
$font-weight-black: 900;
