// IMPROVEMENT: create/manage variables with list, map, map-get...
// Examples: for breakpoints, color-palette...

// mixins
@import 'mixins/mixin-colors';
@import 'mixins/mixin-display';

// SCSS base variables for global application
$size-default: 0.8rem; // Global unit
$base-step: $size-default / 2;
$css-namespace: 'dc__'; // prefix css classes (namespacing) | pc = initials for 'parc-car'
$path-fonts: '../fonts';

// Others variables
@import 'scss/var-colors';
@import 'scss/var-grid';
@import 'scss/var-layout';
@import 'scss/var-screen';
@import 'scss/var-typography';
@import 'scss/var-utils';
@import 'scss/var-spacers';
