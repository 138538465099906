/**
 * Mixin for hide content visually
 * Recommendation: when you call this mixin, use a 'position: relative' rule
 * into a close parent container to limit 'position: absolute' effect
 */
@mixin visuallyhidden {
    position: absolute;
    overflow: hidden;
    width: 0.1rem;
    height: 0.1rem;
    margin: -0.1rem;
    border: 0;
    padding: 0;
    clip: rect(0, 0, 0, 0); // old specification
    clip-path: inset(100%); // new specification
}

/**
 * Mixin for force 1 line text with '...' truncation
 * TODO: check https://caniuse.com/#search=hyphen for future method of hyphenation
 */
@mixin hyphenation {
    display: block;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
}
