//
//  Color variables
//  _____________________________________________

$color-white             : #fff !default;    // TODO Remove redefinition of the same hexa in Descours
$color-black             : #000 !default;    // TODO Remove redefinition of the same hexa in Descours

$defaultGray             : #1b1b1b !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray19            : #303030 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray20            : #333 !default;    // TODO Remove redefinition of the same hexa in Descours
$color-gray34            : #868686 !default;
$color-gray37            : #5e5e5e !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray40            : #666 !default;    // TODO Remove redefinition of the same hexa in Descours
$color-gray43            : #6d6d6d !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray46            : #d6d4d4 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray52            : #858585 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray55            : #8c8c8c !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray56            : #8f8f8f !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray60            : #999 !default;    // TODO Remove redefinition of the same hexa in Descours
$color-gray62            : #9e9e9e !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray64            : #c7c6c4 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray68            : #adadad !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray76            : #d5d4d2 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray78            : #d1d5d8 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray79            : #c9c9c9 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray80            : #ccc !default;    // TODO Remove redefinition of the same hexa in Descours
$color-gray82            : #d1d1d1 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray83            : #d2d2d2 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray89            : #e3e3e3 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray90            : #e5e5e5 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray91            : #e8e8e8 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray92            : #ebebeb !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray94            : #c7c6c4 !default; // Same as $color-gray64 && Remove redefinition of the same hexa in Descours
$color-gray95            : #f2f2f2 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray96            : #f5f4f2 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray97            : #dcdee2 !default; // TODO Remove redefinition of the same hexa in Descours
$color-white-smoke       : #f5f5f5 !default; // TODO Remove redefinition of the same hexa in Descours
$color-white-dark-smoke  : #efefef !default; // TODO Remove redefinition of the same hexa in Descours
$color-white-fog         : #faf9f7 !default; // TODO Remove redefinition of the same hexa in Descours

$color-gray-light0       : #f6f6f6 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-light01      : #f4f4f4 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-light02      : #f5f5f5 !default; // Same as $color-white-smoke && Remove redefinition of the same hexa in Descours
$color-gray-light1       : #e5efe5 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-light2       : #b2b2b2 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-light3       : #aeaeae !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-light4       : #cecece !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-light5       : #c1c1c1 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-light6       : #eeeeec !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-light7       : #1d1d1b !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-light8       : #dddddd !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-light9       : #e1e3e5 !default; // TODO Remove redefinition of the same hexa in Descours

$color-gray-middle1      : #e4e4e4 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-middle2      : #c6c6c6 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-middle3      : #7e807e !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-middle4      : #6e716e !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-middle5      : #d4d4d4 !default; // TODO Remove redefinition of the same hexa in Descours

$color-gray-darken0      : #eee !default;    // TODO Remove redefinition of the same hexa in Descours
$color-gray-darken1      : #e2e2e2 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-darken2      : #cdcdcd !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-darken3      : #555 !default;    // TODO Remove redefinition of the same hexa in Descours
$color-gray-darken4      : #494949 !default; // TODO Remove redefinition of the same hexa in Descours
$color-gray-darken5      : #e5e9ec !default; // TODO Remove redefinition of the same hexa in Descours

$color-red9              : #ff0101 !default; // TODO Remove redefinition of the same hexa in Descours
$color-red10             : #de2c19 !default; // TODO Remove redefinition of the same hexa in Descours
$color-red11             : #b30000 !default; // TODO Remove redefinition of the same hexa in Descours
$color-red12             : #d10029 !default; // TODO Remove redefinition of the same hexa in Descours
$color-red13             : #de2c19 !default; // Same as $color-red10 && Remove redefinition of the same hexa in Descours
$color-red14             : #FF0404 !default; // TODO Remove redefinition of the same hexa in Descours

$color-orange-red1       : #ff5501 !default; // TODO Remove redefinition of the same hexa in Descours
$color-orange-red2       : #ff5601 !default; // TODO Remove redefinition of the same hexa in Descours
$color-orange-red3       : #ff5700 !default; // TODO Remove redefinition of the same hexa in Descours
$color-orange-red4       : #fc5e10 !default; // TODO Remove redefinition of the same hexa in Descours
$color-orange-red5       : #ffa27b !default; // TODO Remove redefinition of the same hexa in Descours

$color-orange1           : #f8b30d !default; // TODO Remove redefinition of the same hexa in Descours
$color-orange2           : #F88E00 !default; // TODO Remove redefinition of the same hexa in Descours

$color-green1            : #27ae60 !default; // TODO Remove redefinition of the same hexa in Descours

$color-dark-green1       : #006400 !default; // TODO Remove redefinition of the same hexa in Descours
//$color-dark-green2       : #27ae60 !default;

$color-blue1             : #0b7de6 !default;
$color-blue2             : #006ea6 !default; // TODO Remove redefinition of the same hexa in Descours
$color-blue3             : #00a3cf !default; // TODO Remove redefinition of the same hexa in Descours
$color-blue4             : #005397 !default; // TODO Remove redefinition of the same hexa in Descours
$color-sky-blue1         : #68a8e0 !default; // TODO Remove redefinition of the same hexa in Descours

$color-pink1             : #fae5e5 !default; // TODO Remove redefinition of the same hexa in Descours
$color-dark-pink1        : #800080 !default; // TODO Remove redefinition of the same hexa in Descours

$color-brownie1          : #6f4400 !default; // TODO Remove redefinition of the same hexa in Descours
$color-brownie-light1    : #c07600 !default; // TODO Remove redefinition of the same hexa in Descours

$color-yellow-light1     : #fdf0d5 !default; // TODO Remove redefinition of the same hexa in Descours
$color-yellow-light2     : #ffee9c !default; // TODO Remove redefinition of the same hexa in Descours
$color-yellow-light3     : #d6ca8e !default; // TODO Remove redefinition of the same hexa in Descours
$color-yellow            : #feed04 !default; // TODO Remove redefinition of the same hexa in Descours

//
//  Color nesting
//  ---------------------------------------------

$primary__color          : $color-gray20 !default; // TODO Remove redefinition of the same hexa in Descours
$primary__color__dark    : darken($primary__color, 35%) !default; // #000  // TODO Remove redefinition of the same hexa in Descours
$primary__color__darker  : darken($primary__color, 13.5%) !default; // #111  // TODO Remove redefinition of the same hexa in Descours
$primary__color__lighter : lighten($primary__color, 29%) !default; // #7d7d7d  // TODO Remove redefinition of the same hexa in Descours
$primary__color__light   : lighten($primary__color, 45%) !default; // #a6a6a6  // TODO Remove redefinition of the same hexa in Descours

$secondary__color        : $color-gray91 !default; // TODO Remove redefinition of the same hexa in Descours
$secondary__color__light : lighten($secondary__color, 5%) !default; // TODO Remove redefinition of the same hexa in Descours

$page__background-color  : $color-white !default; // TODO Remove redefinition of the same hexa in Descours
$panel__background-color : darken($page__background-color, 6%) !default; // TODO Remove redefinition of the same hexa in Descours

$active__color           : $color-orange-red1 !default;
$error__color            : $color-red10 !default;
